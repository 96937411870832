module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"ssr":false,"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Invest","short_name":"Invest","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"25cede9638ccfec6298b63102225fd2d"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"2309021389374981","version":"v3.3","xfbml":true,"cookie":false,"includeInDevelopment":false,"debug":false,"language":"en_US"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"24915384","respectDNT":false,"productionOnly":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
